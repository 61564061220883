// Generated by Framer (4c611f3)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["AOC4wmua9"];

const variantClassNames = {AOC4wmua9: "framer-v-1lop9uf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "AOC4wmua9", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-99FJN", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1lop9uf", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"AOC4wmua9"} ref={ref} style={{...style}}><SVG className={"framer-lfdds1"} data-framer-name={"Frame"} layout={"position"} layoutDependency={layoutDependency} layoutId={"wzIVD3Edu"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 24 24\"><path d=\"M 4.75 6.75 C 4.75 5.645 5.645 4.75 6.75 4.75 L 17.25 4.75 C 18.355 4.75 19.25 5.645 19.25 6.75 L 19.25 17.25 C 19.25 18.355 18.355 19.25 17.25 19.25 L 6.75 19.25 C 5.645 19.25 4.75 18.355 4.75 17.25 Z\" fill=\"transparent\" stroke-width=\"1.5\" stroke=\"var(--token-72be4b58-ad30-40d4-9403-e97ffbb2d1b3, rgb(246, 246, 246)) /* {&quot;name&quot;:&quot;Text&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-dasharray=\"\"></path><path d=\"M 15.25 12 L 9.75 8.75 L 9.75 15.25 Z\" fill=\"transparent\" stroke-width=\"1.5\" stroke=\"var(--token-72be4b58-ad30-40d4-9403-e97ffbb2d1b3, rgb(246, 246, 246)) /* {&quot;name&quot;:&quot;Text&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-dasharray=\"\"></path></svg>"} svgContentId={1498729438} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-99FJN [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-99FJN .framer-1d16t9d { display: block; }", ".framer-99FJN .framer-1lop9uf { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-99FJN .framer-lfdds1 { flex: none; height: 24px; left: 0px; position: absolute; top: 0px; width: 24px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FrameriJZ8btewZ: React.ComponentType<Props> = withCSS(Component, css, "framer-99FJN") as typeof Component;
export default FrameriJZ8btewZ;

FrameriJZ8btewZ.displayName = "Nav/Icon -- YouTube";

FrameriJZ8btewZ.defaultProps = {height: 24, width: 24};

addFonts(FrameriJZ8btewZ, [])